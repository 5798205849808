import React, { JSX } from "react";

import { Collapse } from "antd";

import GraphicsOrderCollapseHeaderBlock from "./Graphics-order-collapse-header-block";
import GraphicsForms from "../forms";

import type { TID, TNode } from "graphql/type/common-gql-types";
import type { TGraphicsOrder } from "graphql/type/graphics-gql-types";


interface IGraphicsOrdersBlockProps {
    standId           : TID
    graphicsOrders    : TNode<TGraphicsOrder>[]
    organizerId       : TID
}

const GraphicsOrdersBlock : React.FC<IGraphicsOrdersBlockProps> = (
    {
        standId,
        organizerId,
        graphicsOrders,
    }) : JSX.Element => {

    return (
        <div className="graphics-orders-block-wrap">
            { graphicsOrders && graphicsOrders.length ?
                <Collapse
                    className        = "graphics-orders-block"
                    accordion        = { true }
                    defaultActiveKey = { '' }
                    expandIcon       = {
                        () => <span className="plus-minus-toggle"/>
                    }
                    items={
                        graphicsOrders.map(({ node }, idx) => {

                            return {
                                key : `${node.id}_${idx}`,
                                label :
                                    <GraphicsOrderCollapseHeaderBlock
                                        graphicsOrder = {{
                                            ...node,
                                            ...(!node.number && { number : idx + 1 })
                                        }}
                                    />,
                                children :
                                    <GraphicsForms.OrderEdit
                                        standId       = { standId }
                                        organizerId   = { organizerId }
                                        graphicsOrder = { node }
                                    />
                            };
                        })
                    }
                /> :
                <div className="no-data">
                    <p className="label">No graphics orders yet</p>
                </div>
            }
        </div>
    );
};

export default GraphicsOrdersBlock;