import React, { JSX } from "react";

import { Select } from "antd";

import { reactiveVarHelper } from "common/helpers";
import { Icons } from "components/layout";

import type { TID, TVariableItem } from "graphql/type/common-gql-types";


const { Option } = Select;

interface IVariablesSelectProps {
    selectedValue   ?: string
    variableGroup    : string
    className       ?: string
    showEmptyOption ?: boolean
    value           ?: string
    allowedOptions  ?: {
        [key : string] : TID | string
    }[]
    //optionFilter    ?: <T, U, >( allOptions : T, permittedOptions : U ) => Exclude<T, U>
}

const FromVariablesSelect : React.FC<IVariablesSelectProps> = (
    {
        selectedValue,
        variableGroup,
        className = '',
        showEmptyOption = true,
        allowedOptions,
        ...props
    }) : JSX.Element => {

    const variableLists = reactiveVarHelper().get('variableLists') as TVariableItem[];

    const variables = variableLists.filter(
        (listItem :  TVariableItem) => {

            if(allowedOptions && listItem.group_name === variableGroup){
                return allowedOptions.findIndex(
                    (allowedItem)  => allowedItem.slug === listItem.slug
                ) !== -1;
            }

            return listItem.group_name === variableGroup;
        }
    ) ;

    return(
        <Select
            { ...props }
            className     = { className }
            defaultValue  = { selectedValue || "" }
            suffixIcon    = { <Icons.Arrow /> }
        >
            { (!variables.length || showEmptyOption) && !props.value &&
                <Option key={ 0 } value={ "" }>—</Option>
            }
            { variables.map(
                ({ id, value, slug }) =>
                    <Option key={ id } value={ slug }>{ value }</Option>
            )
            }
        </Select>
    );
};

export default React.memo(FromVariablesSelect);