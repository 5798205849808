import React, { JSX, useState } from "react";

import { useOutletContext } from "react-router-dom";

import { Blocks, Buttons, Fields } from "components/layout";
import { ROUTES } from "components/routes";
import { LinkWithIcon } from "components/service";
import Stand from "components/stand";

import type { TFilterFields } from "common/types";


const StandListPage : React.FC = () : JSX.Element => {

    const { ee } = useOutletContext<{ee : any}>();
    const [ filters, setFilters ] = useState<TFilterFields>({ text : "" });

    return(
        <div className="page stand-list-page">
            <Blocks.PageHeader
                title={ `${ ee.title } stand list`}
                backButton = {
                    <Buttons.PageBack
                        path = { ROUTES.EXHIBITION_LIST }
                        text = "To exhibition list"
                    />
                }
            />
            <div className="page-content-wrap">
                <Blocks.TableHeader
                    filters = {<>
                        <Fields.Search
                            filters={ filters }
                            setFilters={ setFilters }
                        />
                    </>}
                    actions = {<>
                        <LinkWithIcon
                            route={ "STAND_ITEM_CREATE_FULL" }
                            routeParams={ { 'eeId' : ee.id } }
                        >
                            Add stand
                        </LinkWithIcon>
                    </>}
                />
                <Stand.Tables.All
                    eeId    = { ee.id }
                    filters = { filters }
                />
            </div>
        </div>
    );
};

export default StandListPage;