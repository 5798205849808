import React, { JSX, useEffect, useState } from "react";

import { useQuery } from "@apollo/client";
import { message } from "antd";

import { GET_GRAPHICS_LAYOUTS } from "graphql/query/graphics-q.gql";

import GraphicsButtons from "../buttons";
import GraphicsLists from "../lists";
import { Blocks, Fields } from "components/layout";
import { Loader } from "components/request-result";

import type { TID } from "graphql/type/common-gql-types";
import type { TGraphicsLayout } from "graphql/type/graphics-gql-types";


interface IGraphicsApprovalLayoutBlockProps {
    standId     : TID
}

const GraphicsApprovalLayoutBlock : React.FC<IGraphicsApprovalLayoutBlockProps> = ({ standId }) : JSX.Element => {

    const { data, loading } = useQuery( GET_GRAPHICS_LAYOUTS, {
        variables : {
            where : {
                column : "STAND_ID",
                operator : "EQ",
                value : standId
            },
            orderBy : [ { column : "ORDER", order : "DESC" } ]
        },
        fetchPolicy : "cache-first"
    });

    const [ layouts, setLayouts ] = useState<TGraphicsLayout[]>([]);
    const [ messageApi, contextHolder ] = message.useMessage();


    const {
        graphicsLayoutsCursor : {
            edges : layoutEdges = []
        } = {}
    } = data || {};

    useEffect(() => {
        if(!loading){
            setLayouts(
                layoutEdges.map( ({ node }) => {
                    return { ...node };
                })
            );
        }
    }, [ layoutEdges ]);

    return(
        <Blocks.Card
            Header    = { "Approval layout" }
            className = { "graphics-approval-layout-block" }
            Action    = {
                <GraphicsButtons.LayoutUpload
                    standId      = { standId }
                    layoutsState = { [ layouts, setLayouts ] }
                    messageApi   = { messageApi }
                />
            }
        >
            { !!layouts.length &&
                <>
                    <div>
                        <label className="label">Readiness status</label>
                        <Fields.FromVariablesSelect
                            className     = { 'layout-status' }
                            variableGroup = { 'layout status' }
                        />
                    </div>
                    <GraphicsLists.LayoutFile
                        layouts = { layouts }
                        onDelete  = { (layoutId) => {
                            setLayouts(
                                layouts.filter(( { id }) => id !== layoutId)
                            );
                        }}
                    />
                </>
            }
            { loading &&
                <Loader type="block" />
            }
            { (!loading && !layouts.length) &&
                <div className="no-data">
                    <p className="label">No available Layouts yet</p>
                </div>
            }
            { contextHolder }
        </Blocks.Card>
    );
};

export default GraphicsApprovalLayoutBlock;