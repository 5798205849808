import React, { JSX } from "react";

import { Button } from "antd";

import { GRAPHICS_PRINT_DATA_DELETE } from "graphql/mutation/graphics-m.gql";

import { Icons } from "components/layout";
import { DeleteButton } from "components/service";

import type { TID } from "graphql/type/common-gql-types";


interface IGraphicsPrintDataAddDeleteTextButtonProps {
    textId       ?: TID
    textExist     : boolean
    setTextExist  : (exist : boolean) => void
    onDelete     ?: ( fileId : TID ) => void
}

const GraphicsPrintDataAddDeleteTextButton : React.FC<IGraphicsPrintDataAddDeleteTextButtonProps> = (
    {
        textId,
        setTextExist ,
        textExist,
        onDelete = () => {},
    }) : JSX.Element => {

    return (
        <>
            { textExist && textId ?
                <DeleteButton
                    id         = { textId }
                    buttonType = { "text" }
                    iconName   = { "Delete" }
                    query      = { GRAPHICS_PRINT_DATA_DELETE }
                    onSuccess  = {(_, cache) => {
                        cache.evict({
                            id : cache.identify(
                                { id : textId, __typename : "GraphicsPrintData" }
                            )
                        });

                        setTextExist(false);
                        onDelete(textId);
                    }}
                    confirmText = { `Do you want delete this text?` }
                >Delete</DeleteButton> :
                <Button
                    type      = "text"
                    className = {`${ !textExist ? "inverted" : '' }`}
                    disabled  = { textExist }
                    onClick   = { () => {
                        setTextExist(true);
                    }}
                >
                    <Icons.Plus />
                    Add
                </Button>
            }
        </>
    );
};

export default GraphicsPrintDataAddDeleteTextButton;
