import React, { JSX, useEffect } from "react";

import { Button } from "antd";
import { MessageInstance } from "antd/es/message/interface";

import { GRAPHICS_PRINT_DATA_CREATE } from "graphql/mutation/graphics-m.gql";

import { Icons } from "components/layout";
import { UploadFile } from "components/service";

import type { TID } from "graphql/type/common-gql-types";
import type { TGraphicsPrintData } from "graphql/type/graphics-gql-types";


interface IGraphicsPrintDataAddFileBtnProps {
    orderId : TID
    printDataState : [
        values : TGraphicsPrintData[],
        set : ( values : TGraphicsPrintData[] ) => void
    ]
    messageApi : MessageInstance
}

const GraphicsPrintDataAddFileButton : React.FC<IGraphicsPrintDataAddFileBtnProps> = (
    {
        orderId,
        printDataState,
        messageApi
    }) : JSX.Element => {

    const [ printData, setPrintData ] = printDataState;

    return(
        <>
            <UploadFile
                variables = {{
                    input : {
                        graphics_order_id : orderId,
                        type : 'file'
                    }
                }}
                accept    = { "*/*" }
                onSuccess = { ( { graphicsPrintDataCreate } ) => {

                    const { graphicsPrintData } = graphicsPrintDataCreate;

                    messageApi.open({
                        key : 'file-loading-message',
                        type : 'success',
                        content : 'File uploaded successfully',
                        duration : 1,
                    }).then(
                        () => {
                            setPrintData([
                                ...printData,
                                { ...graphicsPrintData }
                            ]);
                        }
                    );
                }}
                mutation = { GRAPHICS_PRINT_DATA_CREATE }
            >
                {({ loading }) => {
                    useEffect(() => {
                        if(loading){
                            messageApi.open({
                                key : 'file-loading-message',
                                type : 'loading',
                                content : 'Please wait while the file is being uploaded'
                            });
                        }
                    }, [ loading ]);

                    return(
                        <>
                            <Button
                                className = "upload-print-data"
                                type      = "text"
                            >
                                <Icons.Upload /> Upload file
                            </Button>
                        </>
                    );
                }}
            </UploadFile>
        </>

    );
};

export default GraphicsPrintDataAddFileButton;