import React, { JSX } from "react";

import { STAND_DELETE } from "graphql/mutation/stand-m.gql";

import { Menus } from "components/layout";
import { DeleteButton, LinkWithIcon } from "components/service";

import type { TID } from "graphql/type/common-gql-types";


interface IStandTableItemMenuProps{
    eeId : TID
    standId : TID
    companyName : string
}

const StandTableItemMenu : React.FC<IStandTableItemMenuProps> = (
    {
        companyName ,
        ...props
    }) : JSX.Element => {

    return(
        <Menus.Popover>
            <LinkWithIcon
                route       = { "STAND_ITEM_FULL" }
                routeParams = { { ...props } }
                icon        = { "Edit" }
                className   = { "link-text" }
            >
                Edit
            </LinkWithIcon>
            <LinkWithIcon
                route       = { "STAND_ITEM_EQUIPMENT_FULL" }
                routeParams = { { ...props } }
                icon        = { "ExpoDetails" }
                className   = { "link-text" }
            >
                Equipment
            </LinkWithIcon>
            <LinkWithIcon
                route       = { "STAND_ITEM_GRAPHIC_FULL" }
                routeParams = { { ...props } }
                icon        = { "GraphicList" }
                className   = { "link-text" }
            >
                Graphics
            </LinkWithIcon>
            <LinkWithIcon
                route       = { "STAND_ITEM_TASKS_FULL" }
                routeParams = { { ...props } }
                icon        = { "CheckList" }
                className   = { "link-text" }
            >
                Tasks
            </LinkWithIcon>
            <DeleteButton
                className   = { "stand-delete" }
                buttonType  = { "text" }
                id          = { Number(props.standId) }
                query       = { STAND_DELETE }
                reFetch     = {[ "GetStands" ]}
                confirmText = { `Do you want to delete ${ companyName } stand?` }
            >
                Delete
            </DeleteButton>
        </Menus.Popover>
    );
};

export default StandTableItemMenu;