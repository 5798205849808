import { gql } from "@apollo/client";


export const GRAPHICS_ORDER_CREATE = gql`
    mutation GraphicsOrderCreate($input: GraphicsOrderFieldsInput!) {
        graphicsOrderCreate(input: $input){
            label
            message
            graphicsOrder {
                id
                number
                stand_id
                graphic_supplier_id
                status
                mp_status
                quantity
                product_type
                material
                color
                visible_width
                visible_height
                actual_width
                actual_height
                montage
                montage_comment
                component
                price_offer
                area_order
                area_paid
                supplier_order
                price
                price_montage
                total
                created_at
                updated_at
            }
        }
    }
`;

export const GRAPHICS_ORDER_UPDATE = gql`
    mutation GraphicsOrderUpdate($input: GraphicsOrderFieldsInput!) {
        graphicsOrderUpdate(input: $input){
            label
            message
            graphicsOrder {
                id
                number
                stand_id
                graphic_supplier_id
                status
                mp_status
                quantity
                product_type
                material
                color
                visible_width
                visible_height
                actual_width
                actual_height
                montage
                montage_comment
                component
                price_offer
                area_order
                area_paid
                supplier_order
                price
                price_montage
                total
                created_at
                updated_at
            }
        }
    }
`;

export const GRAPHICS_ORDER_DELETE = gql`
    mutation graphicsOrderDelete($id: ID!) {
        graphicsOrderDelete(id: $id){
            label
            message
        }
    }
`;


export const GRAPHICS_PRINT_DATA_CREATE = gql`
    mutation graphicsPrintDataCreate($input: GraphicsPrintDataUploadInput!) {
        graphicsPrintDataCreate(input: $input){
            label
            message
            graphicsPrintData {
                id
                graphics_order_id
                status
                type
                url_storage
                server_name
                extension
                title
                description
                order
                getPathPublic
                created_at
                updated_at
            }
        }
    }
`;

export const GRAPHICS_PRINT_DATA_UPDATE = gql`
    mutation GraphicsPrintDataUpdate($input: GraphicsPrintDataFieldsInput!) {
        graphicsPrintDataUpdate(input: $input){
            label
            message
            graphicsPrintData {
                id
                graphics_order_id
                status
                type
                url_storage
                server_name
                extension
                title
                description
                order
                getPathPublic
                created_at
                updated_at
            }
        }
    }
`;

export const GRAPHICS_PRINT_DATA_DELETE = gql`
    mutation graphicsPrintDataDelete($id: ID!) {
        graphicsPrintDataDelete(id: $id){
            label
            message
        }
    }
`;

export const GRAPHICS_LAYOUT_CREATE = gql`
    mutation graphicsLayoutCreate($input: GraphicsLayoutUploadInput!) {
        graphicsLayoutCreate(input: $input){
            label
            message
            graphicsLayout {
                id
                status
                server_name
                extension
                title
                getPathPublic
                order
                created_at
                updated_at
            }
        }
    }
`;

export const GRAPHICS_LAYOUT_DELETE = gql`
    mutation graphicsLayoutDelete($id: ID!) {
        graphicsLayoutDelete(id: $id){
            label
            message
        }
    }
`;