import {
    ICheckListItem,
    TCheckListGroups,
    TCheckListSectionNames
} from "../check-list-types";

const structureChecklist = (checkListData : ICheckListItem[]) : TCheckListGroups => {

    const checkListGroups : TCheckListGroups = structuredClone(initialCheckListGroups);

    checkListData.forEach(
        (item : ICheckListItem) => {
            const { list_group : listGroup, active } = item;

            if(fieldGroupSectionConnection[listGroup]){

                const sectionName: TCheckListSectionNames  = fieldGroupSectionConnection[listGroup];
                const fieldGroup = checkListGroups[sectionName].fieldGroups[listGroup];

                const fieldExistIdx = fieldGroup?.findIndex( ( listItem) => listItem.id === item.id);

                if( typeof fieldExistIdx === "number" && fieldExistIdx >= 0 ){

                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    const oldItem = checkListGroups[sectionName].fieldGroups[listGroup][fieldExistIdx];
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    checkListGroups[sectionName].fieldGroups[listGroup][fieldExistIdx] = item;

                    if(oldItem.active !== item.active){
                        checkListGroups[sectionName].fieldStatistic.finished += oldItem.active ? -1 : +1;
                    }

                } else {

                    if(active){
                        checkListGroups[sectionName].fieldStatistic.finished++;
                    }

                    checkListGroups[sectionName].fieldGroups[listGroup]?.push(item);
                }

            }

        }
    );

    return checkListGroups;
};

export const initialCheckListGroups : TCheckListGroups = {
    'ExpoDates' : {
        fieldStatistic : {
            total : 25,
            finished : 0
        },
        fieldGroups : {
            'OfficialDates' : [],
            'AdditionalDates' : [],
            'InternalDates' : []
        }
    },
    'Crafts' : {
        fieldStatistic : {
            total : 69,
            finished : 0
        },
        fieldGroups : {
            'Logistics' : [],
            'Assembly' : [],
            'StandConstruction' : [],
            'FloorCoverings' : [],
            'Furniture' : [],
            'Graphics' : [],
            'Electric' : [],
            'Team' : [],
            'Documents' : []
        }
    },
    'SitePlanning' : {
        fieldStatistic : {
            total : 10,
            finished : 0
        },
        fieldGroups : {
            'SitePlanning' : [],
        }
    },
    'Participants' : {
        fieldStatistic : {
            total : 12,
            finished : 0
        },
        fieldGroups : {
            'Organizer' : [],
            'Subcontractors' : [],
            'TeamOnSite' : []
        }
    },
    'Invoice' : {
        fieldStatistic : {
            total : 8,
            finished : 0
        },
        fieldGroups : {
            'Invoice' : [],
        }
    },
    'Recalculation' : {
        fieldStatistic : {
            total : 6,
            finished : 0
        },
        fieldGroups : {
            'Recalculation' : [],
        }
    },
};

export const fieldGroupSectionConnection = {
    'OfficialDates' : 'ExpoDates',
    'AdditionalDates' : 'ExpoDates',
    'InternalDates' : 'ExpoDates',
    'Logistics' : 'Crafts',
    'Assembly' : 'Crafts',
    'StandConstruction' : 'Crafts',
    'FloorCoverings' : 'Crafts',
    'Furniture' : 'Crafts',
    'Graphics' : 'Crafts',
    'Electric' : 'Crafts',
    'Team' : 'Crafts',
    'Documents' : 'Crafts',
    'Organizer' : 'Participants',
    'Subcontractors' : 'Participants',
    'TeamOnSite' : 'Participants',
    'Invoice' : 'Invoice',
    'SitePlanning' : 'SitePlanning',
    'Recalculation' : 'Recalculation'
} as const;

export default structureChecklist;