import React, { JSX, ReactNode } from "react";


interface ICardBlockProps {
    Header    ?: JSX.Element | string,
    Action    ?: JSX.Element
    children  ?: ReactNode
    className ?: string
}

const CardBlock : React.FC<ICardBlockProps> = ({
        Header,
        Action,
        className,
        children
    }) : JSX.Element => {

    return(
        <div className={`card-block ${ className ? className : "" }`}>
            <div className="card-block-header">
                <h4 className="inverted">{ Header }</h4>
                <div className="card-block-header-action">
                    { Action }
                </div>
            </div>
            <div className="card-block-body">
                { children }
            </div>
        </div>
    );
};

export default CardBlock;