import React, { JSX } from "react";

import { useMutation } from "@apollo/client";
import { Button, Form, Input } from "antd";

import {
    GRAPHICS_PRINT_DATA_CREATE,
    GRAPHICS_PRINT_DATA_UPDATE
} from "graphql/mutation/graphics-m.gql";

import { Icons } from "components/layout";
import { useNotification } from "components/use-hook";

import type { TID } from "graphql/type/common-gql-types";
import type { TGraphicsPrintData } from "graphql/type/graphics-gql-types";


interface IGraphicsPrintDataTextEditFormProps {
    orderId      : TID
    textData    ?: TGraphicsPrintData
    onUpdate    ?: (data : TGraphicsPrintData) => void
}

const GraphicsPrintDataTextEditForm : React.FC<IGraphicsPrintDataTextEditFormProps> = (
    {
        orderId,
        textData,
        onUpdate = () => {}
    }) : JSX.Element => {

    const { nError, nSuccess } = useNotification();

    const [ graphicsPrintDataTextEdit, { loading } ] = useMutation(
        textData ? GRAPHICS_PRINT_DATA_UPDATE  : GRAPHICS_PRINT_DATA_CREATE,
        {
            update(cache, { data }) {

                const { [textData ? 'graphicsPrintDataUpdate' : 'graphicsPrintDataCreate' ] : {
                    label,
                    message,
                    graphicsPrintData
                }  } = data;

                nSuccess(label, message);

                onUpdate(graphicsPrintData as TGraphicsPrintData);
            },
            onError(error) {
                console.error(error);
            },
        }
    );

    const [ form ] = Form.useForm();

    return(
        <div className="graphics-print-data-text-edit-form">
            <Form
                className={"inner-forms"}
                form={form}
                layout="vertical"
                initialValues={
                    textData ? {
                        description : textData.description
                    } : {}
                }
                onFinish={(values) => {
                    if (!loading) {
                        graphicsPrintDataTextEdit({
                            variables : {
                                input : {
                                    ...values,
                                    ...(textData && { id : textData.id }),
                                    ...(!textData && { graphics_order_id : orderId }),
                                    type : 'text',
                                }
                            }
                        }).catch(nError);
                    }
                }}
            >
                <Form.Item
                    label={"Text"}
                    name="description"
                    rules={[ {
                        required : true,
                        message : "required"
                    } ]}
                >
                    <Input.TextArea
                        name="print-text"
                        autoSize={{ minRows : 6, maxRows : 6 }}
                    />
                </Form.Item>
                <div className="form-actions">
                    <div>
                        <Button
                            type="primary"
                            htmlType="submit"
                        >
                            <Icons.Edit loading={ loading }/>
                            { !textData ? 'Add text' : 'Save text' }
                        </Button>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default GraphicsPrintDataTextEditForm;