import React, { JSX, ReactNode } from "react";

import { Button, Popover } from "antd";

import { Icons } from "components/layout";


interface IPopoverMenuHolderProps {
    children     : ReactNode
    iconName    ?: keyof typeof Icons
    iconText    ?: string
    btnClass    ?: string
}

const PopoverMenuHolder : React.FC<IPopoverMenuHolderProps> = (
    {
        iconName = 'ThreeDot',
        iconText ,
        btnClass,
        children
    }) : JSX.Element  => {

    const PopoverIcon = Icons[iconName];
    const buttonClass = `${ btnClass || '' } ${ iconText ? 'custom' : '' }`;

    return(
        <Popover
            className = "popover-menu-holder"
            placement = "bottomRight"
            content   = { children }
            trigger   = { [ "click" ] }
            align     = {{ offset : [ 3, 2 ] }}
            //onOpenChange = { (open) => { console.log(open); } }
        >
            <Button type="text" className={ `popover-btn ${buttonClass}` }>
                <PopoverIcon />
                { iconText ? ` ${ iconText }` : null }
            </Button>
        </Popover>
    );
};

export default PopoverMenuHolder;