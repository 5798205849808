import React, { JSX } from "react";

import { generatePath, Link, useParams } from "react-router-dom";

import Icons from "../icons";


interface IPageBackButtonProps {
    path : string
    text : string
}

const PageBackButton : React.FC<IPageBackButtonProps> = (
    {
        path,
        text
    }) : JSX.Element => {

    const pParams = useParams();

    return(
        <Link className = "page-back-button"
              to        = { generatePath(path,  pParams) }
        >
            <Icons.Arrow />
            { text }
        </Link>
    );
};

export default PageBackButton;