import { changeDataHelper, initDataHelper } from "./graphics-order-edit-helper";
import graphicsTableHelper from "./graphics-table-helper";


const GraphicsHelpers = {
    table : graphicsTableHelper,
    editForm : {
        changedData : changeDataHelper,
        initialData : initDataHelper
    },
};

export default GraphicsHelpers;
