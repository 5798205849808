import GraphicsOrderCreateForm from "./Graphics-order-create-form";
import GraphicsOrderEditForm from "./Graphics-order-edit-form";
import GraphicsPrintDataLinkEditForm from "./Graphics-print-data-link-edit-form";
import GraphicsPrintDataTextEdit from "./Graphics-print-data-text-edit";

import "./graphics-forms.scss";


const GraphicsForms = {
    OrderCreate : GraphicsOrderCreateForm,
    OrderEdit : GraphicsOrderEditForm,
    PrintDataLinkEdit : GraphicsPrintDataLinkEditForm,
    PrintDataTextEdit : GraphicsPrintDataTextEdit
};

export default GraphicsForms;