import React, { JSX } from "react";


interface GraphicsOrderPageActionsBlockProps {
    totalOrder ?: number
    children   ?: React.ReactNode
}

const GraphicsOrderPageActionsBlock : React.FC<GraphicsOrderPageActionsBlockProps> = (
    {
        totalOrder = 0,
        children
    }) : JSX.Element => {
    
    return (
        <div className="graphics-order-page-actions">
            <h3>
                {  totalOrder === 1 ?
                    `There is ${ totalOrder } graphics order` :
                    `There are ${ totalOrder } graphics orders`
                }
            </h3>
            <div className="actions">
                { children }
            </div>
        </div>
    );
};

export default GraphicsOrderPageActionsBlock;