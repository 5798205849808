import GraphicsBlocks from "./blocks";
import GraphicsButtons from "./buttons";
import GraphicsFields from "./fields";
import GraphicsForms from "./forms";
import GraphicsHelpers from "./helpers";
import GraphicsLists from "./lists";
import GraphicsTables from "./tables";


const Graphics  = {
    Forms : GraphicsForms,
    Blocks : GraphicsBlocks,
    Fields : GraphicsFields,
    Buttons : GraphicsButtons,
    Helpers : GraphicsHelpers,
    Tables : GraphicsTables,
    Lists : GraphicsLists
};

export default Graphics;