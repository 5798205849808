import GraphicsLayoutFileList from "./Graphics-layout-file-list";
import GraphicsPrintDataFileList from "./Graphics-print-data-file-list";

import "./graphics-list.scss";


const GraphicsLists = {
    PrintDataFile : GraphicsPrintDataFileList,
    LayoutFile : GraphicsLayoutFileList,
};

export default GraphicsLists;