import React, { JSX, useState } from "react";

import { Button } from "antd";

import GraphicsBlocks from "../blocks";
import { Icons } from "components/layout";
import { DrawerStandard } from "components/service";

import type { TID } from "graphql/type/common-gql-types";
import type  { TGraphicsPrintData } from "graphql/type/graphics-gql-types";


interface EquipmentSelectModalProps {
    btnClass       ?: string
    orderNumber     : number
    orderId         : TID
    printData       : TGraphicsPrintData[]
}

const GraphicsPrintDataDrawer : React.FC<EquipmentSelectModalProps> = (
    {
        btnClass = '',
        orderNumber,
        printData,
        orderId
       //...props
    }) : JSX.Element => {

    const printDataState = useState<TGraphicsPrintData[]>(printData || []);
    const [ openDrawer, setOpenDrawer ] = useState( false );

    return (
        <>
            <Button
                type      = "text"
                className = { btnClass }
                onClick   = { () => setOpenDrawer(true) }
            >
                <Icons.Attach /> Print data ({ printDataState[0].length || 0 })
            </Button>
            <DrawerStandard
                title      = { <h2>Print data of order №{ orderNumber }</h2> }
                isOpen     = { openDrawer }
                extraClass = { "graphics-print-data-drawer" }
                close      = { () => setOpenDrawer(false) }
            >
                <GraphicsBlocks.PrintData
                    printDataState = { printDataState }
                    orderId        = { orderId }
                    closeDrawer    = { () => setOpenDrawer(false) }
                />
            </DrawerStandard>
        </>

    );
};

export default GraphicsPrintDataDrawer;