import React, { JSX, useEffect } from "react";

import { Button } from "antd";
import { MessageInstance } from "antd/es/message/interface";

import { GRAPHICS_LAYOUT_CREATE } from "graphql/mutation/graphics-m.gql";

import { Icons } from "components/layout";
import { UploadFile } from "components/service";

import type { TID } from "graphql/type/common-gql-types";
import type { TGraphicsLayout } from "graphql/type/graphics-gql-types";


interface IGraphicsPrintDataAddFileBtnProps {
    standId : TID
    layoutsState : [
        values : TGraphicsLayout[],
        set : ( values : TGraphicsLayout[] ) => void
    ]
    messageApi : MessageInstance
}

const GraphicsLayoutUploadButton : React.FC<IGraphicsPrintDataAddFileBtnProps> = (
    {
        standId,
        layoutsState,
        messageApi
    }) : JSX.Element => {

    const [ layouts, setLayouts ] = layoutsState;

    const versionNumber = (layouts.length > 0 ? layouts[0].order : 0) + 1;

    return(
        <>
            <UploadFile
                variables = {{
                    input : {
                        stand_id : standId,
                        title : `Version ${ versionNumber }`,
                        order : versionNumber
                    }
                }}
                accept    = { "*/*" }
                onSuccess = { ( { graphicsLayoutCreate } ) => {

                    const { graphicsLayout } = graphicsLayoutCreate;

                    messageApi.open({
                        key : 'file-loading-message',
                        type : 'success',
                        content : 'File uploaded successfully',
                        duration : 1,
                    }).then(
                        () => {
                            setLayouts([
                                { ...graphicsLayout },
                                ...layouts,
                            ]);
                        }
                    );
                }}
                mutation = { GRAPHICS_LAYOUT_CREATE }
            >
                {({ loading }) => {
                    useEffect(() => {
                        if(loading){
                            messageApi.open({
                                key : 'file-loading-message',
                                type : 'loading',
                                content : 'Please wait while the file is being uploaded'
                            });
                        }
                    }, [ loading ]);

                    return(
                        <>
                            <Button
                                className = "upload-layout inverted"
                                type      = "text"
                            >
                                <Icons.Upload /> Upload version
                            </Button>
                        </>
                    );
                }}
            </UploadFile>
        </>

    );
};

export default GraphicsLayoutUploadButton;