import React, { JSX, useState } from "react";

import { Button } from "antd";

import { STAND_CAD_UPLOAD } from "graphql/mutation/stand-m.gql";

import { Icons } from "components/layout";
import { DeleteButton, UploadFile } from "components/service";

import { EFileUploadModel, EFileUploadModelField } from "common/types";
import type { TStandCad } from "graphql/type/stand-gql-types";


interface ITaskCadUploadProps {
    cad    : TStandCad
}

const TaskCadUploadField : React.FC<ITaskCadUploadProps> = ({ cad }) : JSX.Element => {

    const [ cadState, setCadState ] = useState<TStandCad>({ ...cad });

    const uploadVars = {
        input : {
            model : EFileUploadModel.CAD,
            model_id : cad.id,
            model_field : EFileUploadModelField.FILE_PATH
        }
    };

    const fileExist = cadState && cadState.file_path;

    return(
        <div className={ `task-cad-upload-wrap ${ fileExist ? 'file-exist' : '' }` }>
            { !fileExist ?
                <UploadFile
                    variables = {{ ...uploadVars }}
                    accept    = { "application/pdf" }
                    onSuccess = { ({ fileUploadModel }) => {
                        setCadState(fileUploadModel.model);
                    }}
                    mutation = { STAND_CAD_UPLOAD }
                >
                    {( { loading } ) => {
                        return(
                            <Button
                                className = "upload-cad inverted"
                                type      = "text"
                            >
                                <Icons.Upload loading={ loading } /> Upload CAD file
                            </Button>
                        );
                    }}
                </UploadFile> :
                <div className="cad-file">
                    <div className="text">
                        <span className="icon-wrap">
                            <Icons.Check/>
                        </span>
                        CAD version has been uploaded
                    </div>
                    <div className="actions">
                        <DeleteButton
                            id         = { cad.id }
                            buttonType = { "default" }
                            query      = { STAND_CAD_UPLOAD }
                            variables  = {{ ...uploadVars }}
                            onSuccess  = {
                                () => setCadState({ ...cad, file_path : '' })
                            }
                        >
                            Delete
                        </DeleteButton>
                        <a className = { `link-button` }
                           href      = { cad.file_path }
                           target    = "_blank"
                        >
                            <Icons.Download />
                            Download
                        </a>
                    </div>
                </div>
            }
        </div>
    );
};

export default TaskCadUploadField;