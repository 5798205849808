import React, { JSX } from "react";

import { useQuery } from "@apollo/client";
import { Select } from "antd";

import { GET_EE_HALLS } from "graphql/query/exhibition-q.gql";

import { Icons } from "components/layout";


const { Option } = Select;

type TExhibitionHallsProps = {
    eeId     : number,
    id      ?: string
};

const ExhibitionHallSelect : React.FC<TExhibitionHallsProps> = ({ eeId, ...props }) : JSX.Element => {

    const { data, loading } = useQuery( GET_EE_HALLS, {
        variables : {
            where : {
                column : "EE_ID",
                operator : "EQ",
                value : eeId
            },
            orderBy : [ { column : "TITLE", order : "ASC" } ]
        },
        fetchPolicy : "cache-first"
    });

    const {
        halls : {
            data : halls = []
        } = {}
    } = data || {};


    return(
        <Select
            { ...props }
            loading       = { loading }
            defaultValue  = { ""! }
            suffixIcon    = { <Icons.Arrow /> }
        >
            <Option key={ 0 } value={ "" }>Not selected</Option>
            { halls.map(
                ({ id, title, number } : { id: number, title: string, number: number }) => {
                    return(
                        <Option key={ id } value={ id } >{ title } #{ number }</Option>
                    );
                }
            )
            }
        </Select>
    );
};

export default React.memo(ExhibitionHallSelect);