import React, { JSX, useEffect } from 'react';

import { useMutation } from "@apollo/client";
import { Button, Form, FormInstance, Input, InputNumber } from 'antd';
import { generatePath, useNavigate } from "react-router-dom";

import { STAND_CREATE, STAND_DELETE, STAND_UPDATE } from "graphql/mutation/stand-m.gql";

import StandFields from "../fields";
import StandHelpers from "../helpers";
import StandModals from "../modals";
import { reactiveVarHelper } from "common/helpers";
import Exhibition from "components/exhibition";
import Flooring from "components/flooring";
import { Icons } from "components/layout";
import { ROUTES } from "components/routes";
import { DeleteButton } from "components/service";
import Team from "components/team";
import { useNotification } from "components/use-hook";

import type{ TStandTypeProps } from "../fields/Stand-type-select";
import { ETeamRoles } from "common/types";
import type { TStandItem } from "graphql/type/stand-gql-types";
import type { TStandType } from "graphql/type/stand-type-gql-types";


type TStandEditFormProps = {
    ee : any,
    stand ?: TStandItem<TStandType>,
    isCreate : boolean
};

const StandEditForm : React.FC<TStandEditFormProps> = (
    {
        isCreate,
        ee,
        stand
    }) : JSX.Element => {

    const navigate = useNavigate(),
          { nError, nSuccess } = useNotification();

    const rVar = reactiveVarHelper(),
          me = rVar.get('me');

    const [ standEdit, { loading } ] = useMutation(
        isCreate ? STAND_CREATE : STAND_UPDATE,
        {
            update(cache, { data }) {

                const { [isCreate ? "standCreate" : "standUpdate" ] : {
                    label,
                    message,
                    stand,
                }  } = data;

                if(isCreate){
                    navigate(
                        generatePath(
                            ROUTES.STAND_ITEM_FULL,
                            {
                                eeId : Number(ee.id),
                                standId : Number(stand.id)
                            }
                        )
                    );
                }

                nSuccess(label, message);

            },
            onError(error) {
                console.error(error);
            }
        }
    );

    const [ form ] = Form.useForm();

    const standType  : TStandTypeProps = Form.useWatch<string, FormInstance>('standType', form),
          flooringType = Form.useWatch<string, FormInstance>('flooringType', form) || undefined,
          width : number = Number(Form.useWatch<string, FormInstance>('size_width', form)) || 0,
          height : number = Number(Form.useWatch<string, FormInstance>('size_height', form)) || 0;

    useEffect( () => {
        form.setFieldValue('stand_area', width * height);
    }, [ width, height ] );

    useEffect( () => {
        if(standType){
            if(!standType.orientations.find(
                (item) => item.orientation_key === form.getFieldValue('orientation_key'))
            ){
                form.setFieldValue('orientation_key', '');
            }

            if(!stand || !stand.flooring_id){
                form.setFieldValue('flooringType', '');
            }
        }
    }, [ standType ]);

    return (
        <div className="stand-edit-form ">
            <Form
                className = { "inner-forms" }
                form      = { form }
                layout    = "vertical"
                initialValues={
                    !isCreate  && stand ? StandHelpers.editForm.initialData(stand, me.lang_api) : undefined
                }
                onFinish={(values) => {
                    if (!loading) {
                        standEdit({
                            variables : {
                                input : StandHelpers.editForm.prepareData(
                                    {
                                        ...values,
                                        id : !isCreate  && stand ? stand.id : null,
                                        ee_id : ee.id
                                    }
                                )
                            }
                        }).catch(nError);
                    }
                }}
            >
                <Form.Item
                    label={"Company name"}
                    name="company_name"
                    rules={[
                        {
                            required : true,
                            message : "required"
                        }
                    ]}
                >
                    <Input name="company-name"/>
                </Form.Item>
                <div className="grid-two-col">
                    <Form.Item
                        label = {"Hall"}
                        name  = "hall_id"
                    >
                        <Exhibition.Fields.HallSelect
                            eeId = { ee.id }
                        />
                    </Form.Item>
                    <Form.Item
                        label = {"Block"}
                        name  = "block"
                    >
                        <Input name="stand-block"/>
                    </Form.Item>
                </div>
                <div className="grid-two-col">
                    <Form.Item
                        label = {"Stand number"}
                        name  = "stand_number"
                        rules = {[
                            {
                                required : true,
                                message : "required"
                            }
                        ]}
                    >
                        <Input name="stand-number"/>
                    </Form.Item>
                    <Form.Item
                        label = {"Proauf"}
                        name  = "proauf"
                    >
                        <Input name="stand-proauf"/>
                    </Form.Item>
                </div>
                <div className="grid-two-col">
                    <div className = "stand-type-wrap">
                        <Form.Item
                            label = {"Stand type"}
                            shouldUpdate
                            name  = "standType"
                            rules = {[
                                {
                                    required : true,
                                    message : "required"
                                }
                            ]}
                        >
                            <StandFields.TypeSelect
                                orgId    = { ee.organizer_id }
                                etId     = { ee.exhibition_type_id }
                                disabled = { !isCreate }
                            />
                        </Form.Item>
                        { stand && standType &&
                            <StandModals.ChangeTypeSize
                                ee        = { ee }
                                stand     = { stand }
                                standType = { standType }
                                callback  = { () => form.resetFields() }
                            />
                        }
                    </div>

                    { standType &&
                        <Form.Item
                            label = {"Stand Orientation"}
                            name  = "orientation_key"
                            rules = {[ {
                                required : true,
                                message : "required"
                            } ]}
                        >
                            <StandFields.OrientationSelect
                                standTypeId = { standType.standTypeId }
                            />
                        </Form.Item>
                    }
                </div>
                { standType &&
                    <div className="grid-two-col">
                        <div>
                            <h5 className="label">
                                Size
                                { standType.min && standType.max ?
                                    ` (from ${ standType.min } to ${ standType.max } sq.m)` : ""
                                }
                            </h5>
                            <div className = 'stand-size-wrap'>
                                <Form.Item
                                    name  = "size_width"
                                    shouldUpdate
                                    rules = {[
                                        {
                                            type : "number",
                                            min : 0
                                        }
                                    ]}
                                >
                                    <InputNumber name       = "stand-width"
                                                 addonAfter = "m"
                                                 disabled   = { !isCreate }
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="size_height"
                                    shouldUpdate
                                    rules={[
                                        {
                                            type : "number",
                                            min : 0
                                        }
                                    ]}
                                >
                                    <InputNumber name       = "stand-height"
                                                 addonAfter = "m"
                                                 disabled   = { !isCreate }
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="stand_area"
                                    shouldUpdate
                                    rules={[
                                        {
                                            type : "number",
                                            min : standType.min || 0,
                                            max : standType.max || 999
                                        },
                                    ]}
                                >
                                    <InputNumber  disabled
                                                  name       ="stand-area"
                                                  addonAfter = "sq.m"
                                    />
                                </Form.Item>
                                { stand && standType &&
                                    <StandModals.ChangeTypeSize
                                        ee        = { ee }
                                        stand     = { stand }
                                        standType = { standType }
                                        callback  = { () => form.resetFields() }
                                    />
                                }
                            </div>
                        </div>
                        <Form.Item
                            label = {"FLOOR COVERING"}
                            name  = "flooringType"
                        >
                            <Flooring.Fields.Select
                                orgId = { ee.organizer_id }
                                etId  = { ee.exhibition_type_id }
                                standTypeId      = { Number(standType.standTypeId) }
                                standTypeGroupId = { Number(standType.value) }
                                currentFlooring  = { flooringType ? {
                                    id : flooringType.value,
                                    title : flooringType.label
                                } : undefined }
                            />
                        </Form.Item>
                    </div>
                }
                <div className="grid-two-col">
                    <Form.Item
                        label = { "Lead project manager" }
                        name  = "projectManager"
                    >
                        <Team.Fields.UserSelect
                            modalTitle    = { "Choose Project manager" }
                            teamId        = { ee.team_id }
                            teamRole      = { ETeamRoles.PROJECT_MANAGER }
                            currentMember = { stand && stand.relProjectManager ? stand.relProjectManager : undefined  }
                        />
                    </Form.Item>
                    <Form.Item
                        label = {"Autocad engineer"}
                        name  = "autocadEngineer"
                    >
                        <Team.Fields.UserSelect
                            modalTitle    = { "Choose Autocad engineer" }
                            teamId        = { ee.team_id }
                            teamRole      = { ETeamRoles.DRAFTSMEN }
                            currentMember = { stand && stand.relAutocadEngineer ? stand.relAutocadEngineer : undefined  }
                        />
                    </Form.Item>
                </div>
                <div className="form-actions">
                    <div>
                        {!isCreate && stand &&
                            <DeleteButton
                                className  = { "stand-delete" }
                                buttonType = { "default" }
                                id         = { Number(stand.id) }
                                query      = { STAND_DELETE }
                            >
                                Delete Stand
                            </DeleteButton>
                        }
                    </div>
                    <div>
                        <Button
                            type     = "primary"
                            htmlType = "submit"
                        >
                            <Icons.Edit loading={ loading }/>
                            { isCreate ? 'Create stand' : 'Save changes' }
                        </Button>
                    </div>
                </div>
            </Form>
        </div>
    );
};


export default React.memo(StandEditForm);