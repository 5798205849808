import React, { FunctionComponent, JSX } from 'react';

import Icon from '@ant-design/icons';

import { IIcon } from "./icons-interfaces";
import { Loader } from "components/request-result";


const LinkSvg : React.FC = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1">
        <path d="M26.88,33l-2.92,2.92c-1.65,1.65-3.8,2.6-6.06,2.66-2.19.06-4.26-.73-5.77-2.24-3.14-3.14-2.95-8.45.42-11.82l2.92-2.92c.78-.78.78-2.05,0-2.83-.78-.78-2.05-.78-2.83,0l-2.92,2.92c-4.94,4.94-5.12,12.78-.42,17.48,2.21,2.21,5.16,3.41,8.34,3.41.12,0,.24,0,.37,0,3.28-.09,6.39-1.46,8.77-3.83l2.92-2.92c.78-.78.78-2.05,0-2.83-.78-.78-2.05-.78-2.83,0Z"/>
        <path d="M39.55,8.93c-4.7-4.7-12.54-4.51-17.48.42l-2.9,2.9c-.78.78-.78,2.05,0,2.83.78.78,2.05.78,2.83,0l2.9-2.9c3.38-3.38,8.68-3.56,11.82-.42s2.95,8.45-.42,11.82l-2.9,2.9c-.78.78-.78,2.05,0,2.83.39.39.9.59,1.41.59s1.02-.2,1.41-.59l2.9-2.9c4.94-4.94,5.12-12.78.42-17.48Z"/>
        <path d="M32.4,16.08c-.78-.78-2.05-.78-2.83,0l-13.1,13.1c-.78.78-.78,2.05,0,2.83.39.39.9.59,1.41.59s1.02-.2,1.41-.59l13.1-13.1c.78-.78.78-2.05,0-2.83Z"/>
    </svg>
);

const LinkIcon: FunctionComponent<IIcon> = (props): JSX.Element => {

    if (props.loading) {
        return <Loader/>;
    }

    return <Icon component={ LinkSvg } className={props.className} aria-label="link icon"/>;

};


export default LinkIcon;