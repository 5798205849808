import ExhibitionBlocks from "./blocks";
import ExhibitionButtons from "./buttons";
import ExhibitionFields from "./fields";
import ExhibitionForms from "./forms";
import ExhibitionHelpers from "./helpers";
import ExhibitionHooks from "./hooks";
import ExhibitionTables from "./tables";
 

const Exhibition  = {
    Blocks : ExhibitionBlocks,
    Forms : ExhibitionForms,
    Fields : ExhibitionFields,
    Buttons : ExhibitionButtons,
    Helpers : ExhibitionHelpers,
    Tables : ExhibitionTables,
    useHook : ExhibitionHooks,
};

export default Exhibition;