import GraphicsLayoutUploadButton from "./Graphics-layout-upload-button";
import GraphicsPrintDataAddDeleteTextButton from "./Graphics-print-data-add-delete-text-button";
import GraphicsPrintDataAddFileButton from "./Graphics-print-data-add-file-button";


const GraphicsButtons = {
    PrintData : {
        AddFile : GraphicsPrintDataAddFileButton,
        AddDeleteText : GraphicsPrintDataAddDeleteTextButton
    },
    LayoutUpload : GraphicsLayoutUploadButton
};

export default GraphicsButtons;