import { FormInstance } from "antd";
import { TGraphicsOrder, /*TGraphicsSupplier,*/ TGraphicsSupplierRule } from "graphql/type/graphics-gql-types";

export const initDataHelper = ( graphicsOrder : TGraphicsOrder /*, graphicsSupplier : TGraphicsSupplier */) => {

    const textOrder = graphicsOrder?.relPrintData?.find(
        ({ type }) => type === 'text'
    );

    const letterAmount = textOrder ?
        textOrder.description
            .replaceAll(' ', '')
            .replaceAll('\n', '').length : 0;

    return {
        ...graphicsOrder,
        mp_status : graphicsOrder.mp_status === null ? '' : graphicsOrder.mp_status,
        color : graphicsOrder.color === null ? '' : graphicsOrder.color,
        material : graphicsOrder.material === null ? '' : graphicsOrder.material,
        number_letters : letterAmount
    };
};


const calculateTotal = (
    {
        fullPrice = 0,
        quantity = 1,
        actualArea = 0,
        calculatedArea = 1,
        specialPrice = 0,
    }) => {

    if(Number(actualArea.toString()) === 0){
        return (specialPrice * quantity).toFixed(2);
    }

    return parseFloat((fullPrice * quantity * calculatedArea + specialPrice * quantity * actualArea).toFixed(2));
};
export const changeDataHelper = (
    changedValues : any,
    allValues : any,
    rules : TGraphicsSupplierRule[],
    form  : FormInstance) => {

    const rule = rules.find(({ id }) => id === allValues.product_type);
    const montage = (!!allValues.montage && allValues.montage !== '') ? allValues.montage : 'assembly_type_at_the_stand';

    const area = Number(allValues.actual_width) / 1000 * Number(allValues.actual_height) / 1000;

    const totalFields  = {
        quantity : allValues.quantity,
        price : rule?.price_manufacture || 0,
        montagePrice : 0,
        fullPrice : 0,
        specialPrice : allValues.price_offer,
        actualArea : area,
        calculatedArea : (area === 0 || area > 1) ? area : 1,
    };

    if(montage === 'assembly_type_at_the_stand'){
        totalFields.montagePrice = rule?.price_on_stand || 0;
    } else if(montage === 'assembly_type_workshop'){
        totalFields.montagePrice = rule?.price_on_supplier || 0;
    }

    if(rule?.lettering){
        const paidLetters = allValues.number_letters - rule.free_symbols;

        if(paidLetters > 0){
            const additionalPrice = parseFloat((paidLetters * rule.price_additional).toFixed(2));

            totalFields.price = additionalPrice + totalFields.price;
            totalFields.montagePrice = additionalPrice + totalFields.montagePrice;
        }
    }

    totalFields.fullPrice = totalFields.price + totalFields.montagePrice;

    if(changedValues.product_type || changedValues.montage){
        form.setFieldsValue({
            montage,
            'price' : totalFields.price,
            'price_montage' : totalFields.montagePrice,
            'total' : calculateTotal(totalFields)
        });
    }

    if(changedValues.actual_width || changedValues.actual_height){
        form.setFieldsValue({
            'area_order' : parseFloat(area.toFixed(2)),
            'area_paid' : area > 1 ? parseFloat(area.toFixed(2)) : 1,
            'total' : calculateTotal(totalFields)
        });
    }

    if(changedValues.quantity || changedValues.price_offer){
        form.setFieldsValue({
            'price' : totalFields.price,
            'price_montage' : totalFields.montagePrice,
            'total' : calculateTotal(totalFields)
        });
    }

    if(changedValues.graphic_supplier_id){
        form.setFieldsValue({
            product_type : '',
            montage : '',
            'price' : 0,
            'price_montage' : 0,
            'total' : 0
        });
    }
};