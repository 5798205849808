import React, { JSX, ReactNode } from "react";

import Icons from "../../icons";
import { PopoverMenuHolder } from "components/service";


interface IPopoverMenuProps {
    children   : ReactNode
    iconName  ?: keyof typeof Icons
    iconText  ?: string
    btnClass  ?: string
}

const PopoverMenu : React.FC<IPopoverMenuProps> = (
    {
        children,
        ...props
    }) : JSX.Element => {

    return(
        <PopoverMenuHolder { ...props }>
            <ul
                onClick   = {
                    () => {
                        const element = document.querySelector(".ant-popover-open") as HTMLElement;

                        if(element){
                            element.click();
                        }
                    }
                }
                className="popover-menu"
            >
                { Array.isArray(children) ?
                    children.map(
                        (item, idx) => <li key={ idx }>{ item }</li>
                    ) :
                    children
                }
            </ul>
        </PopoverMenuHolder>
    );
};

export default PopoverMenu;