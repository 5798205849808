import GraphicsOrderCreateModal from "./Graphics-order-create-modal";
import GraphicsPrintDataLinkModal from "./Graphics-print-data-add-link-modal";
import GraphicsPrintDataDrawer from "./Graphics-print-data-drawer";

import './graphics-modals.scss';


const GraphicsModals = {
    OrderCreate : GraphicsOrderCreateModal,
    PrintData : GraphicsPrintDataDrawer,
    PrintDataLink : GraphicsPrintDataLinkModal
};

export default GraphicsModals;