import React, { JSX } from "react";

import { useOutletContext } from "react-router-dom";


const ExhibitionChangeBlock : React.FC = () : JSX.Element => {

    const { ee } = useOutletContext<{ee : any}>();

    return (
        <div className="exhibition-selector">
            <span className="pre-text">You are working on</span>
            <span className="current-ee">
                { ee.title }
            </span>
        </div>
    );
};


export default ExhibitionChangeBlock;