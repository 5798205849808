import React, { JSX } from "react";


import { GRAPHICS_PRINT_DATA_DELETE } from "graphql/mutation/graphics-m.gql";

import { Icons } from "components/layout";
import { DeleteButton } from "components/service";

import type { TID } from "graphql/type/common-gql-types";
import type { TGraphicsPrintData } from "graphql/type/graphics-gql-types";


interface IGraphicsPrintDataFileListProps {
    printData  ?: TGraphicsPrintData[]
    onDelete   ?: ( fileId : TID ) => void
}

const GraphicsPrintDataFileList : React.FC<IGraphicsPrintDataFileListProps> = (
    {
        printData = [],
        onDelete  = () => {},
    }): JSX.Element | null => {

    if(!printData.length){
        return null;
    }

    return (
        <div className="graphics-print-data-file-list-wrap">
            <ul  className="graphics-print-data-file-list">
                { printData.map(
                    ( data ) => {

                        const deleteConfirmText = data.type === 'link' ?
                            `Do you want delete link? \n ${data.title}` :
                            `Do you want delete file? \n ${data.server_name}.${data.extension}`;

                        return (
                            <li
                                key       = { data.id }
                                className = "graphics-print-data-file-list-item"
                            >
                                { data.type === 'file' &&
                                    <div className="name">
                                        <Icons.Documents type={data.extension}/>
                                        <span>{data.server_name}.{data.extension}</span>
                                    </div>
                                }
                                { data.type === 'link' &&
                                    <div className="name">
                                        <Icons.Link/>
                                        <span>{ data.title }</span>
                                    </div>
                                }
                                <div className="actions">
                                    <a className  = "link-text"
                                       href       = {
                                           data.type === 'file' ?  data.getPathPublic : data.description
                                       }
                                       download
                                       target     = "_blank"
                                       aria-label ="download"
                                    >
                                        <Icons.Download/>
                                    </a>
                                    <DeleteButton
                                        id         = { data.id }
                                        buttonType = { "text" }
                                        iconName   = { "Delete" }
                                        query      = { GRAPHICS_PRINT_DATA_DELETE }
                                        onSuccess  = {(_, cache) => {
                                            cache.evict({
                                                id : cache.identify(
                                                    { id : data.id, __typename : "GraphicsPrintData" }
                                                )
                                            });

                                            onDelete(data.id);
                                        }}
                                        confirmText = { deleteConfirmText }
                                    />
                                </div>
                            </li>
                        );
                    })
                }
            </ul>
        </div>
    );
};

export default GraphicsPrintDataFileList;