import React, { JSX, useState } from "react";

import Exhibition from "components/exhibition";
import { Blocks, Fields } from "components/layout";
import { LinkWithIcon } from "components/service";

import type { TFilterFields } from "common/types";


const ExhibitionsPage : React.FC = () : JSX.Element => {

    const [ filters, setFilters ] = useState<TFilterFields>({ text : "" });

    return(
        <div className="page exhibitions-page">
            <Blocks.PageHeader
                title       = { "Exhibitions" }
                hideEEBlock = { true }
            />
            <div className="page-content-wrap">
                <Blocks.TableHeader
                    filters = {<>
                        <Fields.Search
                            filters={ filters }
                            setFilters={ setFilters }
                        />
                    </>}
                    actions = {<>
                        <LinkWithIcon route={ "EXHIBITION_ITEM_CREATE" } >
                            Add exhibition
                        </LinkWithIcon>
                    </>}
                />
                <Exhibition.Tables.All
                    filters={ filters }
                />
            </div>
        </div>

    );
};

export default ExhibitionsPage;